<template>
  <v-container>
    <v-row class="mb-2" style="margin-top: 200px !important">
      <span class="group pa-2">
        <v-icon class="mr-2" @click="before()">navigate_before</v-icon>
        <input v-model.number="page" type="number" style="width: 4em; border: 1px solid; p-2"> / {{ numPages }}
        <v-icon class="mr-2" @click="next()">navigate_next</v-icon>
        <v-icon class="mr-2" @click="rotate += 90">rotate_right</v-icon>
        <v-icon class="mr-2" @click="rotate -= 90">rotate_left</v-icon>
        <v-icon class="mr-2" @click="download()">cloud_download</v-icon>
        <v-icon v-if="1==0" class="mr-2" @click="$refs.pdf.print(100, 1)">print</v-icon>
      </span>
    </v-row>
    <pdf ref="pdf" 
      class="pdf-content"
      :src="src" 
      :page="page" 
      :rotate="rotate"
      @loaded="loaded"  
      @error="error" 
      @num-pages="numPages = $event" 
      @link-clicked="page = $event"></pdf>
  </v-container>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: "downloadpdf",
  
  components: {
    pdf
  },

  data() {
    return {
      loading: true,
      page: 1,
      src: pdf.createLoadingTask('https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/freeculture.pdf'),
			loadedRatio: 0,
			numPages: 0,
			rotate: 0,
    }
  },

  created() {

  },

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit('startProcessing', { 'value': true, 'message': 'Cargando...'})
  },

  methods: {
    error: function(err) {
			console.log(err);
    },
    
    loaded: function() {
      this.$store.commit('stopProcessing')
    },

    before: function() {
      if(this.page > 1) {
        this.page--
      } else {
        this.page = 1
      }
    },

    next: function() {
      if(this.page < this.numPages) {
        this.page++
      } else {
        this.page = this.numPages
      }
    },

    download: function() {

    }
  }
}
</script>
<style scoped>
.app-header .content { padding-bottom: 200px !important; }
.pdf-content {
   box-shadow: 0px 10px 30px rgba(209, 213, 223, 0.5) !important;
  border-radius: 20px 20px 0px 20px !important;
}
</style>
